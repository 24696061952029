import instance from "../services/instance";

const GetAllContact = payload => {
  return instance.instance
    .get("/contactUs/all", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};




export default {
  getAllContact: GetAllContact
};
