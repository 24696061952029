    <template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Contact List</div>
            <div class="subtitle-1 font-weight-light">
              A list of contact message senders Appears here
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:item.email="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.phone="{ item }">
                    <v-tooltip bottom v-if="item.phone">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
                        </span>
                      </template>
                      <span>
                        Call
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.phone }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <MoreModal :message="item.message" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MoreModal from "./MoreModal";
import ContactUsApicalls from "../../../services/contact.service";
export default {
  name: "RFP",
  components: {
    MoreModal,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      headers: [
        {
          sortable: true,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Phone",
          value: "phone",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.isLoading = true;
    ContactUsApicalls.getAllContact().then((res) => {
      // console.log(res.data);
      this.items = res.data.data;
      this.isLoading = false;
      // console.log(res.data);
    });
    // .catch(err => {
    //   // console.log(err);
    // });
  },
  methods: {
    RouteFn(id, email) {
      //update Email in Vuex Store
      this.$store.dispatch("setUserEmail", email);
      localStorage.setItem("setUserEmail", email);
      //then do routing
      this.$router.push(`/contacts-view/${id}`);
      // console.log(id);
    },
  },
};
</script>
<style scoped>
/* v-dialog issues */
.v-dialog__content >>> .v-dialog.v-dialog--active {
  overflow: hidden !important;
}
</style>